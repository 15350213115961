import React, { useEffect, useState } from 'react'
import CustomerSearchBox from "../customComponents/CustomerSearchBox";
import ToggleButton from "../customComponents/ToggleButton";
import { TextField } from "@mui/material";
import axios from "axios";
import { address } from "../../constants/address";

const LoyaltyPointsForm = ({header, searchLabel, type, rowData, toggleOff}) => {

    const [formData, setFormData] = useState({
        CUSTNAME: "",
        MOBILENO: "",
        POINTS: '',
        NARRATION: "",
        INVDATE: new Date().toISOString().split("T")[0], // for sql -> .toLocaleDateString('en-GB')
        CASHCR: ""
      });
      type !== 'statement' && console.log(formData)
      type === 'statement' && console.log(formData)
    
      const handlePointInfoChange = (e) => {
        const { name, value } = e.target;
        // For Points, ensure the input is a valid whole number
        if (name === "POINTS" && !/^\d*$/.test(value)) {
          return; // If it's not a valid number, don't update the state
        }
        // Update the state for Points, Narration, and PointModifyDate
        setFormData((prevValue) => ({
          ...prevValue,
          [name]: value, // Dynamically set the value for the corresponding field
        }));
      };

      const handleSearchChange = (value)=>{
        setFormData((preValue)=>({
          ...formData,
          CUSTNAME : value.CUSTNAME,
          MOBILENO : value.MOBILENO
        }))
      }
    
      const handleCASHCR = (value) => {
        setFormData((preValue)=>({
          ...preValue,
          CASHCR : value
        }))
      };
    
      const handlePointsUpdate = async()=>{
        try {
          const token = localStorage.getItem('accessToken')
          const response = await axios.post(`${address}/customers/update-points`,{
           formData : formData
          },{headers:{Authorization:`Bearer ${token}`}})
          if (response.status === 200) {
            console.log(response.data.message)
          }
        } catch (error) {
          console.log('Error in updating poitns.')
        }
      }

      const handleStatementUpdate = async()=>{
        try {
          const token = localStorage.getItem('accessToken')
          const response = await axios.post(`${address}/customers/update-statement`,{
            formData : formData
          },{
            headers:{Authorization:`Bearer ${token}`}
          })
          if (response.status === 200) {
            console.log('Updated successfully')
          }
        } catch (error) {
          console.log('Error in updating statement')
        }
      }

      const handleUpdate = ()=>{
         if (type === 'statement') {
          handleStatementUpdate();
         } else {
          handlePointsUpdate();
         }
      }

      useEffect(()=>{
       type === 'statement' && setFormData((preValue)=>({
        ...preValue,
        MOBILENO : rowData.MOBILENO,
        CUSTNAME : rowData.CUSTNAME,
        INVDATE: new Date(rowData.INVDATE).toISOString().split("T")[0], // for sql -> .toLocaleDateString('en-GB'),
        CASHCR : rowData.CASHCR,
        POINTS : rowData.RCREDIT + rowData.RDEBIT,
        NARRATION : rowData.NARRATION,
        MRN : rowData.MRN
       }))
      },[])

  return (
    <div className="w-full h-full px-10 py-4 rounded-[20px] backdrop-blur-[10px]">
    <div className="font-mont font-semibold border-b pb-2">
      {header}
    </div>

    <div className="mt-8">
      <div className="w-full">
        <CustomerSearchBox
          searchLabel={searchLabel}
          getValue={(value) => {
            handleSearchChange(value);
          }}
        />
      </div>

      <div className="mt-6 flex justify-between ">
          <TextField
            sx={{ width: 200 }}
            value={formData.MOBILENO}
            label="Mobile no."
            variant="standard"
            slotProps={{ input: { readOnly: true } }} // New way to apply readOnly
          />
          <TextField
            sx={{ width: 360 }}
            value={formData.CUSTNAME}
            label="Name"
            variant="standard"
            slotProps={{ input: { readOnly: true } }} // New way to apply readOnly
          />
        </div>

      <div>
        <div className="mt-6 flex justify-between items-end">
          <TextField
            name="INVDATE"
            sx={{ width: 200 }}
            value={formData.INVDATE}
            label="Date"
            variant="standard"
            onChange={handlePointInfoChange}
            type="date"
          />

          <ToggleButton
            type='statement'  // sending this so that we can distinguish between normal form and statement form.
            option={formData.CASHCR}  // sending this to update UI in case of statement.
            CASHCR={(value) => {
              handleCASHCR(value);
            }}
          />
        </div>

        <div className="mt-6 flex justify-between">
          <TextField
            name="POINTS"
            sx={{ width: 200 }}
            value={formData.POINTS}
            label="Loyalty Points"
            variant="standard"
            onChange={handlePointInfoChange}
          />
          <TextField
            name="NARRATION"
            sx={{ width: 360 }}
            value={formData.NARRATION}
            label="Narration"
            variant="standard"
            onChange={handlePointInfoChange}
          />
        </div>

        <div className='flex space-x-4'>
          <button 
          onClick={handleUpdate}
          disabled={formData.MOBILENO === '' || formData.POINTS === '' || formData.CASHCR === ''}
          className="mt-8 py-2 px-8 border bg-third text-white text-sm font-mont font-semibold rounded-[5px] hover:bg-third/70">
            Update
          </button>
          {type === 'statement' && <button 
          onClick={toggleOff}
          className="mt-8 py-2 px-8 border border-fifth  text-fifth text-sm font-mont font-semibold rounded-[5px] hover:bg-white">
            Cancel
          </button>
          }
        </div>
      </div>
    </div>
  </div>
  )
}

export default LoyaltyPointsForm
