import React, { useState, useEffect } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

const ToggleButton = ({ CASHCR, type, option }) => {
  const [activeButton, setActiveButton] = useState('');

  // Using useEffect to set activeButton based on 'type === statement' only once when the component mounts
  useEffect(() => {
    if (type === 'statement') {
      setActiveButton(option); // Set the initial value from the table
    }
  }, [type, option]);  // Runs when 'type' or 'option' changes

  const handleSwitch = (option) => {
    setActiveButton(option);  // This is to set UI according to method
    CASHCR(option); // This is to set the CR or DB value to form
  };

  return (
    <div className="flex font-mont font-semibold text-sm">
      <button
        onClick={() => { handleSwitch('CR') }}
        className={`flex w-[180px] space-x-1 justify-center items-center h-[40px] border border-black/25 rounded-l-[5px] ${
          activeButton === 'CR' ? "bg-third text-white" : "text-black/70"
        }`}
      >
        <AddCircleOutlineOutlinedIcon fontSize="small" />{" "}
        <p>Points Allocation</p>
      </button>

      <button
        onClick={() => { handleSwitch('DB') }}
        className={`flex w-[180px] space-x-1 justify-center items-center h-[40px] border-l-0 border border-black/25 rounded-r-[5px] ${
          activeButton === 'DB' ? "bg-fifth text-white" : "text-black/70"
        }`}
      >
        <RemoveCircleOutlineOutlinedIcon fontSize="small" />{" "}
        <p>Points Deduction</p>
      </button>
    </div>
  );
};

export default ToggleButton;
