import React from "react";
import { Route, Routes } from "react-router-dom";
import MyApp from "./MyApp";
import User from './User'
import Dashboard from './Dashboard'
import SideMenu from "../customComponents/SideMenu";
import LoyaltyPoints from "./LoyaltyPoints";
import Statement from './Statement'
import AlertBox from "../customComponents/AlertBox";

const Home = () => {
  return (
    <div className="flex h-screen bg-white">
      <div className="min-w-[250px] h-full">
        <SideMenu />
      </div>
      <div className="relative w-full overflow-auto">
        <AlertBox/>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="mobile-app" element={<MyApp />} />
          <Route path="loyalty-points" element={<LoyaltyPoints/>} />
          <Route path="statement" element={<Statement/>} />
          <Route path="user" element={<User />} />
        </Routes>
      </div>
    </div>
  );
};

export default Home;
