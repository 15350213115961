import React from 'react'
import Alert from '@mui/material/Alert';

const AlertBox = ({type, message}) => {
  return (
    <div className='absolute bottom-2 left-2 z-50'>
      <Alert severity={type}>{message}</Alert>
    </div>
  )
}

export default AlertBox
