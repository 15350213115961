import React, { useEffect, useState } from "react";
import EditNoteIcon from "@mui/icons-material/EditNote";
import LoyaltyPointsForm from "../home/LoyaltyPointsForm";

const StatementTable = ({ data, dfrom }) => {
  const [rowNumber, setRowNumber] = useState(0);  // This is used for margin fix when rownumber > 9
  const [totalCredit, setTotalCredit] = useState(0);  // generate total credit along with opening balance 
  const [totalDebit, setTotalDebit] = useState(0);  // generate total debit along with opening balance 
  const [balance, setBalance] = useState({        // setting credit or debit according to credit - debit 
    credit: 0,
    debit: 0,
  });
  const [toggleEdit, setToggleEdit] = useState(false); // toggle form for edit.
  const [rowData, setRowData] = useState({}); // here we will get the row data
  //console.log(rowData)

  const handleForm = (row)=>{
    setRowData(row);
    setToggleEdit(true);
  }

  useEffect(() => {
    setRowNumber(data.length);

    // Calculate total credit and debit
    const creditTotal = data.reduce((sum, row) => sum + (row.RCREDIT || 0), 0);
    const debitTotal = data.reduce((sum, row) => sum + (row.RDEBIT || 0), 0);

    setTotalCredit(creditTotal);
    setTotalDebit(debitTotal);

    if (creditTotal > debitTotal) {
      setBalance((preValue) => ({
        ...preValue,
        credit: creditTotal - debitTotal,
        debit: 0,
      }));
    } else {
      setBalance((preValue) => ({
        ...preValue,
        credit: 0,
        debit: debitTotal - creditTotal,
      }));
    }
  }, [data]);

  return (
    <div className="border h-full rounded-[15px] shadow-custom-light overflow-hidden">
      {/* Header */}
      <div
        className={`bg-sixth flex text-white font-mont font-semibold text-sm ${
          rowNumber > 9 && "pr-[6px]"
        }`}
      >
        <div className="w-[6%] py-2 px-3"></div>
        <div className="w-[13%] py-2 px-3">Inv Date</div>
        <div className="w-[40%] py-2 px-3">Narration</div>
        <div className="w-[13%] py-2 px-3">Sale Amt.</div>
        <div className="w-[14%] py-2 px-3 text-right">Credit</div>
        <div className="w-[14%] py-2 px-3 text-right">Debit</div>
      </div>

      <div className="h-[270px] overflow-y-auto font-mont font-bold text-sm">
        {data.length > 0 ? (
          data.map((row, i) => (
            <div
              key={row.MRN}
              className={`flex font-semibold text-sm hover:bg-sixth/30 ${
                i % 2 === 0 ? "bg-sixth/10" : "bg-sixth/5"
              }`}
            >
              <div className="w-[6%] h-[30px] flex items-center px-3">
                {row.TS === "M" && (
                  <button onClick={()=>{handleForm(row)}}>
                    <EditNoteIcon fontSize="small" />
                  </button>
                )}
              </div>
              <div className="w-[13%] h-[30px] flex items-center px-3">
                {row.INVDATE === null
                  ? new Date(dfrom).toLocaleDateString()
                  : new Date(row.INVDATE).toLocaleDateString()}
              </div>
              <div className="w-[40%] h-[30px] flex items-center px-3">
                {row.NARRATION}
              </div>
              <div className="w-[13%] h-[30px] flex items-center px-3">
                {row.SALEAMT}
              </div>
              <div className="w-[14%] h-[30px] flex items-center px-3 justify-end">
                {row.RCREDIT}
              </div>
              <div className="w-[14%] h-[30px] flex items-center px-3 justify-end">
                {row.RDEBIT}
              </div>
            </div>
          ))
        ) : (
          <div className="">
            <img className="w-80 mx-auto" src="/table/tablebg.jpg" alt="" />
            <div className="text-center font-mont font-sm font-semibold text-gray-400">
              No statement generated yet.
            </div>
          </div>
        )}
      </div>

      <div
        className={`flex h-[30px] items-center justify-end font-bold font-mont text-sm border-t ${
          rowNumber > 9 && "pr-[6px]"
        }`}
      >
        <div className="w-[13%] px-3">{"Total"}</div>
        <div className="w-[14%] flex items-center px-3 justify-end text-right">
          {totalCredit}
        </div>
        <div className="w-[14%] flex items-center px-3 justify-end text-right">
          {totalDebit}
        </div>
      </div>

      <div
        className={`flex h-[30px] justify-end font-bold font-mont text-sm ${
          rowNumber > 9 && "pr-[6px]"
        }`}
      >
        <div className="w-[13%] flex items-center px-3 border-t-2 border-sixth">
          {"Balance"}
        </div>
        <div className="w-[14%] flex items-center px-3 justify-end text-right border-t-2 border-sixth">
          {balance.credit}
        </div>
        <div className="w-[14%] flex items-center px-3 justify-end text-right border-t-2 border-sixth">
          {balance.debit}
        </div>
      </div>

     {toggleEdit && 
           <div className="absolute z-10 w-[60%] left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2
           bg-white/80 shadow-custom-medium rounded-[20px] ">
            <LoyaltyPointsForm 
            rowData = {rowData}
            header={"Modify statement"}  
            searchLabel = {'Change cutomer name & mobile no'}
            type={'statement'} 
            toggleOff={()=>{setToggleEdit(false)}}/>
          </div>
     }
    </div>
  );
};

export default StatementTable;